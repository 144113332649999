import React from 'react';
import Logo from './logo.png';
import {Link} from 'react-router-dom';

const Header = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-light main-bg-color">
                <div className="container">
                    <Link className="navbar-brand" to="/" style={{color: 'white'}}>
                        <img src={Logo} alt={'Logo'} style={{height: '50px'}}/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <Link className="nav-link" to="/privacy-policy" style={{color: 'white'}}>Privacy Policy</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/terms-and-conditions" style={{color: 'white'}}>Terms & Conditions</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact-us" style={{color: 'white'}}>Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};


export default Header;