import React, {useEffect, useRef, useState} from "react";
import {PRIMARY_COLOR} from "./config";

const ProgressBar = () => {
    const [currentWidth, setCurrentWidth] = useState(0);
    const interval = useRef(null);

    useEffect(() => {
        if(interval.current !== null)
        {
            clearInterval(interval.current);
        }
        interval.current = setInterval(() => {
            if(currentWidth === 100)
            {
                setCurrentWidth(0);
                return;
            }
            setCurrentWidth(currentWidth + 1);
        }, 200);
        return () => {
            clearInterval(interval.current);
        };
    }, [currentWidth]);

    return (
        <div className="progress" style={{height: '10px'}}>
            <div className="progress-bar" role="progressbar"
                 style={{width: `${currentWidth}%`, height: '10px', backgroundColor: PRIMARY_COLOR}}
                 aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"/>
        </div>
    );
};

export default ProgressBar;