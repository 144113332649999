import React, {useEffect, useRef, useState} from 'react';
import './styles.css';
import FileSelector from "./file-selector";
import SendIcon from './icons/send.svg';
import ClipboardIcon from './icons/clipboard.svg';
import DownloadIcon from './icons/download.svg';
import Intro from "./intro";
import {FIREBASE_CONFIG, PRIMARY_COLOR} from "./config";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import Spinner from "./spinner";
import ProgressBar from "./progress-bar";
import INIT_SESSION_GQL from './init-session.graphql';
import INTERACT_GQL from './interact.graphql';
import CHECK_JOB_STATE_GQL from './check-job-state.graphql';
import {getReCaptchaV3Token, retryHttpCall, submitMutation, waitFor} from "./utils";
import {useApolloClient} from "@apollo/client";
import ErrorAlert from "./error-alert";
import { Helmet } from 'react-helmet';
import Help from "./help";

const defaultProgressMessage = 'Working, please wait..';

const Main = () => {
    const apolloClient = useApolloClient();
    const [action, setAction] = useState('upload'); // or url
    const [selectedFile, setSelectedFile] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [textOutput, setTextOutput] = useState(null);
    const [chartOutput, setChartOutput] = useState(null);
    const [isUsedBefore, setIsUsedBefore] = useState(window.localStorage.getItem('is-used-before') !== null);
    const [responseContainerStyle, setResponseContainerStyle] = useState(null);
    const [progressMessage, setProgressMessage] = useState(defaultProgressMessage);
    const textPromptRef = useRef(null);
    const urlRef = useRef(null);
    const firebaseAnalytics = useRef(null);
    //the Celery job ID for file upload / URL
    const sessionInitJobID = useRef(null);
    const sessionInteractionJobID = useRef(null);
    // to restart session on file or URL change
    const lastSessionInitParams = useRef(null);
    //to measure request time
    const networkRequestStartTime = useRef(null);
    const textPromptMinHeight = 70;
    const textPromptMaxHeight = 500;
    let lastTextPromptHeight = null;

    /**
     * Initialize firebase
     */
    useEffect(() => {
        const app = initializeApp(FIREBASE_CONFIG);
        firebaseAnalytics.current = getAnalytics(app);
    }, []);

    /**
     * Animates response container on first use
     */
    useEffect(() => {
        if(isUsedBefore && responseContainerStyle === null)
        {
            setResponseContainerStyle({height: '60vh', overflowY: 'auto'});
        }
    }, [isUsedBefore, responseContainerStyle]);

    /**
     * Grow textarea as text is typed in
     */
    const onTextPromptInput = ({target}) => {
        if(target.scrollHeight < textPromptMinHeight || target.scrollHeight > textPromptMaxHeight)
        {
            return;
        }
        if(lastTextPromptHeight !== null && target.scrollHeight < lastTextPromptHeight)
        {
            return;
        }
        target.style.height = `${target.scrollHeight}px`;
        lastTextPromptHeight = target.scrollHeight;
    };

    /**
     * Scrolls text prompt into view, centered
     */
    const scrollToPrompt = () => {
        //see style.css, transition of response container takes 500 ms
        setTimeout(() => {
            const element = document.querySelector('#prompt');
            const elementRect = element.getBoundingClientRect();
            const absoluteElementTop = window.pageYOffset + elementRect.top;
            const viewportHeight = window.innerHeight;
            const scrollPosition = absoluteElementTop - (viewportHeight / 2) + (elementRect.height / 2);
            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });
        }, 600);
    };

    /**
     * Scrolls response container into view
     */
    const scrollToResponseContainer = () => {
        try {
            document.getElementById('main-container').scrollIntoView({behavior: 'smooth'});
        }
        catch (err) {}
    };

    /**
     * On file change or key-up event on URL
     * @param input
     */
    const onSelectedFileOrURLChange = (input) => {
        if(action === 'upload')
        {
            setSelectedFile(input);
        }
        else if(urlRef.current === null || urlRef.current.value.trim() === '')
        {
            return;
        }
        let currentState = isUsedBefore;
        window.localStorage.setItem('is-used-before', '1');
        setIsUsedBefore(true);
        if(!currentState)
        {
            scrollToPrompt();
        }
    };

    /**
     * Logs Google Analytics event
     */
    const logGoogleAnalyticsEvent = ({error=null, isInit=false, isInteract=false, requestTimeValue=null, requestTimeResourceName=null}) => {
        if(error !== null)
        {
            logEvent(firebaseAnalytics.current, 'error', {message: error});
            return;
        }
        if(isInit)
        {
            logEvent(firebaseAnalytics.current, 'init', {action, value: action === 'upload'? selectedFile.name: urlRef.current});
            return;
        }
        if(isInteract)
        {
            logEvent(firebaseAnalytics.current, 'interact', {initJobID: sessionInitJobID});
        }
        if(requestTimeValue !== null && requestTimeResourceName !== null)
        {
            logEvent(firebaseAnalytics.current, 'network_request', {name: 'request_round_trip_time', value: requestTimeValue, resource_name: requestTimeResourceName});
        }
    };

    /**
     * Checks job state
     */
    const checkJobState = async (jobID, isSessionInit) => {
        while (true)
        {
            await waitFor(1500);
            const {error: stateError, data: stateData} = await retryHttpCall(submitMutation, [apolloClient, CHECK_JOB_STATE_GQL, {jobID}]);
            if(!!stateError)
            {
                //connection errors: failed to fetch, etc.
                setErrorMessage(stateError);
                setIsSubmitting(false);
                logGoogleAnalyticsEvent({error: `Job state failure: ${stateError}`});
                break;
            }
            if(stateData.result.error !== null)
            {
                //errors thrown by Celery jobs
                setErrorMessage(stateData.result.error);
                setIsSubmitting(false);
                logGoogleAnalyticsEvent({error: `Job state failure: ${stateData.result.error}`});
                break;
            }
            if(stateData.result.response !== null)
            {
                const parsedResponse = JSON.parse(stateData.result.response);
                if(!!parsedResponse.chartURL)
                {
                    setChartOutput(parsedResponse.chartURL);
                }
                else {
                    setTextOutput(parsedResponse.output);
                }
                setIsSubmitting(false);
                scrollToResponseContainer();
                break;
            }
            if(stateData.result.progress !== null)
            {
                setProgressMessage(`Working, ${stateData.result.progress}`);
            }
        }
        logGoogleAnalyticsEvent({
            requestTimeValue: new Date().getTime() - networkRequestStartTime.current,
            requestTimeResourceName: isSessionInit? 'init': 'interaction'
        });
    };

    /**
     * Starts Celery job
     */
    const startJob = async ({textPrompt, reCaptchaToken, url=null}) => {
        setErrorMessage(null);
        setIsSubmitting(true);
        setTextOutput(null);
        setChartOutput(null);
        setProgressMessage(defaultProgressMessage);
        let isInit = false;
        let targetGql;
        const mutationVariables = {
            query: textPrompt,
            reCaptchaToken
        };
        const actionValue = url !== null? url: selectedFile.name;
        if(sessionInitJobID.current === null || lastSessionInitParams.current?.action !== action || lastSessionInitParams.current?.value !== actionValue)
        {
            isInit = true;
            targetGql = INIT_SESSION_GQL;
            mutationVariables.file = selectedFile;
            mutationVariables.url = url;
            logGoogleAnalyticsEvent({isInit: true});
        }
        else {
            targetGql = INTERACT_GQL;
            mutationVariables.sessionInitID = sessionInitJobID.current;
            logGoogleAnalyticsEvent({isInteract: true});
        }
        networkRequestStartTime.current = new Date().getTime();
        const {error, data} = await retryHttpCall(submitMutation, [apolloClient, targetGql, mutationVariables]);
        if(error !== null || data === null)
        {
            const errorMessage = error ?? 'Something is not right!';
            logGoogleAnalyticsEvent({error: `mutation failed: ${errorMessage}`});
            setErrorMessage(errorMessage);
            setIsSubmitting(false);
        }
        else {
            if(isInit)
            {
                sessionInitJobID.current = data.result;
                sessionInteractionJobID.current = null;
            }
            else {
                sessionInteractionJobID.current = data.result;
            }
            lastSessionInitParams.current = {action, value: actionValue};
            await checkJobState(data.result, isInit);
        }
    };

    /**
     * On submit prompt
     */
    const onSubmitPrompt = async () => {
        const textPrompt = textPromptRef.current.value.trim();
        let url = null;
        if(textPrompt === '')
        {
            alert('Enter your prompt');
            return;
        }
        if(action === 'upload' && selectedFile === null)
        {
            alert('Select a file to upload');
            return;
        }
        if(action === 'url')
        {
            url = urlRef.current.value.trim();
            if(url === '')
            {
                alert('Enter a URL');
                return;
            }
        }

        let reCaptchaToken;
        try {
            reCaptchaToken = await getReCaptchaV3Token();
        }
        catch (err)
        {
            logGoogleAnalyticsEvent({error: `Failed to acquire reCaptcha token, ${err}`});
            alert('Failed to initialize!');
            return;
        }
        await startJob({textPrompt, reCaptchaToken, url});
    };

    /**
     * On prompt key down
     */
    const onPromptKeyDown = (event) => {
        if(event.key === 'Enter')
        {
            onSubmitPrompt();
            event.preventDefault();
        }
    };

    return (
        <>
            <Helmet>
                <title>Turn Documents into Summaries, Insights, and Visuals - FileDigest</title>
            </Helmet>
            <main id='main-container' className="container flex-grow-1 mt-5">
                <div className="card mx-auto">
                    <div className="card-header" style={{borderBottom: isUsedBefore? 'inherit': 'none'}}>
                        <div className="row">
                            <div className="col-md-4">
                                <select className="form-select" value={action} onChange={({target}) => setAction(target.value)}>
                                    <option value={'upload'}>Upload file</option>
                                    <option value={'url'}>Enter URL</option>
                                </select>
                            </div>
                            <div className="col-md-6 mt-3 mt-sm-0">
                                {
                                    action === 'upload' &&
                                    <>
                                        <FileSelector setSelectedFile={onSelectedFileOrURLChange} selectedFile={selectedFile}/>
                                        {
                                            !!selectedFile?.name &&
                                            <span className="text-muted ms-2">{selectedFile.name}</span>
                                        }
                                    </>
                                }
                                {
                                    action === 'url' &&
                                    <input type="text" className="form-control" ref={urlRef} onKeyUp={onSelectedFileOrURLChange} autoFocus={true} placeholder={'URL'}/>
                                }
                            </div>
                            <div className="col-md-2" style={{textAlign: 'right'}}>
                                {
                                    textOutput !== null &&
                                    <button className="btn" title="Copy to clipboard" onClick={() => {
                                        const elem = document.createElement('textarea');
                                        elem.value = textOutput;
                                        document.body.appendChild(elem);
                                        elem.select();
                                        document.execCommand('copy');
                                        document.body.removeChild(elem);
                                    }
                                    }>
                                        <img src={ClipboardIcon} alt="Copy to clipboard" style={{width: '24px'}}/>
                                    </button>
                                }
                                {
                                    chartOutput !== null &&
                                    <a href={chartOutput} target="_blank" className="btn btn-link" title={'Download chart'}>
                                        <img src={DownloadIcon} alt="Download chart" style={{width: '30px'}}/>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        isUsedBefore &&
                        <div className="card-body response-container" style={responseContainerStyle ?? {}}>
                            {
                                textOutput === null && chartOutput === null && !isSubmitting &&
                                <Intro/>
                            }
                            {
                                isSubmitting &&
                                <span>{progressMessage}</span>
                            }
                            {
                                textOutput !== null &&
                                <div style={{whiteSpace: 'pre-line'}}>{textOutput}</div>
                            }
                            {
                                chartOutput !== null &&
                                <img src={chartOutput} alt={'Chart'}/>
                            }
                        </div>
                    }
                    {
                        isUsedBefore &&
                        <div className="card-footer mb-5 mb-sm-0" style={{padding: 0, borderTop: 'none', backgroundColor: 'white'}}>
                            <div className="input-group d-flex align-items-center">
                        <textarea ref={textPromptRef} onChange={onTextPromptInput} className={`form-control`} id={'prompt'}
                                  style={{height: `${textPromptMinHeight}px`, fontFamily: 'Roboto-Light'}} autoFocus={true} onKeyDown={onPromptKeyDown}
                                  placeholder={'Ask a question or request a summary of your content  ↵'} disabled={isSubmitting}/>
                                <div className="ms-1"/>
                                {
                                    !isSubmitting &&
                                    <button className="btn btn-primary" style={{backgroundColor: PRIMARY_COLOR}} onClick={onSubmitPrompt}>
                                        <img alt="Send" src={SendIcon} style={{width: '40px'}}/>
                                    </button>
                                }
                                {
                                    isSubmitting &&
                                    <Spinner/>
                                }
                            </div>
                            {
                                isSubmitting &&
                                <ProgressBar/>
                            }
                        </div>
                    }
                </div>
                {
                    errorMessage !== null &&
                    <ErrorAlert>{errorMessage}</ErrorAlert>
                }
                <div className="mt-4"/>
                <Help/>
            </main>
        </>
    );
};

export default Main;