import React from 'react';
import './styles.css';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>
            <main className="container flex-grow-1 mt-5 mb-5">
                <h2>Privacy Policy</h2>
                <br/>
                <h3>Introduction</h3>
                <p>Welcome to FileDigest. This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect, or otherwise handle your Personally Identifiable Information in accordance with our website.</p>

                <h3>What personal information do we collect?</h3>
                <p>We collect personal information such as your name, email address, and file data when you register and use FileDigest's services. The information collected from users helps us personalize and improve your experience with our services.</p>

                <h3>How do we use your information?</h3>
                <p>We may use the information we collect from you when you register, sign up for our services, surf the website, or use certain other site features in the following ways:</p>
                <ul>
                    <li>To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
                    <li>To improve our website in order to better serve you.</li>
                    <li>To allow us to better service you in responding to your customer service requests.</li>
                    <li>To follow up with them after correspondence (live chat, email, or phone inquiries).</li>
                </ul>

                <h3>How do we protect your information?</h3>
                <p>Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. All transactions are processed through a gateway provider and are not stored or processed on our servers.</p>

                <h3>Data Storage and Security</h3>
                <p>The documents you upload are stored securely on our servers and are processed using state-of-the-art AI technologies. We maintain strict data security measures to protect your personal information and files from unauthorized access, use, or disclosure.</p>

                <h3>Retention of Data</h3>
                <p>Files you upload are retained for a period of 30 days after which they are permanently deleted from our servers. However, the summary data generated from these files may be stored indefinitely to enhance our AI models and to provide you with better services in the future.</p>

                <h3>Third-party disclosure</h3>
                <p>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when its release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety.</p>

                <h3>Your Rights</h3>
                <p>You have certain rights regarding your personal data, including the right to access, modify, or delete your data. If you have any questions or concerns about our privacy practices, you can contact us through the contact information provided on our website.</p>

                <h3>Contacting Us</h3>
                <p>If there are any questions regarding this privacy policy, you may contact us using the information on our Contact Us page.</p>

            </main>
        </>
    );
};

export default PrivacyPolicy;