import {RECAPTCHA_V3_SITE_KEY} from "./config";

/**
 * reCaptcha v3 token
 */
export const getReCaptchaV3Token = () => {
    return new Promise((resolve, reject) => {
        window.grecaptcha.ready(async () => {
            try {
                resolve(await window.grecaptcha.execute(RECAPTCHA_V3_SITE_KEY, {action: 'submit'}));
            }
            catch (err)
            {
                reject(err);
            }
        });
    });
};

/**
 * Halts execution for given ms
 */
export const waitFor = (ms) => {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
};

/**
 Submits a mutation
 */
export const submitMutation = async(apolloClient, mutation, variables) => {
    let _error = null;
    let _data = null;
    try {
        const {errors, data} = await apolloClient.mutate({
            mutation,
            variables,
            errorPolicy: 'all'
        });
        if(!!errors)
        {
            _error = errors[0].message;
        }
        else {
            _data = data;
        }
    }
    catch (err)
    {
        _error = err.message;
    }
    return {error: _error, data: _data};
};

/**
 * Retries HTTP connections
 */
export const retryHttpCall = async (handler, params) => {
    const MAX_RETRIES = 10;
    let retries = 0;
    while (retries <= MAX_RETRIES)
    {
        const {error, data} = await handler(...params);
        if(error !== 'Failed to fetch' || retries === MAX_RETRIES)
        {
            return {error, data};
        }
        retries++;
        await waitFor(retries * 1000);
    }
};