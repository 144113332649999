export const PRIMARY_COLOR = '#014157';
//key is also set in public/index.html
export const RECAPTCHA_V3_SITE_KEY = '6Lcc5OomAAAAAHXhlV6ZzE-9YL7GH0uLeB3wk89T';
export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyAeSx8rKHtrQjzS_6Bv66NQrWS7gMAWav8",
    authDomain: "file-digest-1688293643352.firebaseapp.com",
    projectId: "file-digest-1688293643352",
    storageBucket: "file-digest-1688293643352.appspot.com",
    messagingSenderId: "400218196531",
    appId: "1:400218196531:web:f2734a451c61193c6eb146",
    measurementId: "G-GSPJD13XT9"
};