import React from 'react';

const Footer = () => {
    return (
        <footer className="footer mt-auto py-3 main-bg-color">
            <div className="container">
                <span  style={{color: 'white', fontSize: '0.9em'}} className="light-text">© {new Date().getFullYear()} FileDigest. All Rights Reserved</span>
            </div>
        </footer>
    )
};

export default Footer;