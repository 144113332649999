import React from 'react';
import ReactDOM from 'react-dom/client';
import {RetryLink} from '@apollo/client/link/retry';
import {InMemoryCache} from '@apollo/client';
import {ApolloClient, ApolloProvider, ApolloLink} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';
import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';

/**
 * Initialize Apollo client
 */
const linkOptions = {
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_URI.replace('%host', window.location.hostname),
    fetch: (uri, options) => {
        return fetch(uri, options);
    }
};

const retryLink = new RetryLink({
    attempts: {
        max: 5,
        retryIf: (error, _operation) => !!error,
        delay: {
            initial: 300,
            max: Infinity,
            jitter: true,
            factor: 2
        }
    }
});

const apolloMiddleware = new ApolloLink((operation, forward)=>{
    return forward(operation);
});

const link = ApolloLink.from([retryLink, apolloMiddleware, createUploadLink(linkOptions)]);

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ApolloProvider client={client}>
          <App />
      </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
