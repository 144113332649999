import React from 'react';
import ContractIcon from "./icons/contract.svg";
import PdfIcon from "./icons/pdf.svg";
import JobIcon from "./icons/job.svg";
import WebPageIcon from "./icons/web-page.svg";
import ChartIcon from "./icons/chart.svg";
import PromptIcon from "./icons/prompt.svg";
import AiIcon from "./icons/ai.svg";
import BlogIcon from './icons/blog.svg';
import {Link} from 'react-router-dom';

const TitleImg = ({icon}) => <img src={icon} alt={'Title'} style={{width: '25px'}}/>;
const PromptImg = () => <img src={PromptIcon} alt={'Prompt'} style={{width: '20px'}}/>;

const Help = () => {
    return (
        <div className="p-3">
            <h4>Turn Documents into Summaries, Insights, and Visuals with FileDigest.ai</h4>
            {/* contract */}
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <TitleImg icon={ContractIcon}/> Trying to navigate your
                            employment or insurance contract?
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                Upload it, and ask any section you need more clarification on! Here are some sample
                                prompts:
                                <div className="mt-4">
                                            <span className="code">
                                                <PromptImg/>
                                                Summarize the key responsibilities, benefits, and limitations mentioned in my employment contract
                                            </span>
                                </div>
                                <div className="mt-3">
                                            <span className="code">
                                                <PromptImg/>
                                                Can you provide more details on the non-compete clause mentioned in the contract?
                                            </span>
                                </div>
                                <div className="mt-3">
                                            <span className="code">
                                                <PromptImg/>
                                                Summarize the coverage, exclusions, and conditions for claims in my insurance contract
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* research paper */}
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <TitleImg icon={PdfIcon}/> Delving into a lengthy research paper?
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                Use FileDigest to easily summarize key points or ask specific questions, saving you the hassle of reading the entire document.
                                <div className="mt-4">
                                            <span className="code">
                                                <PromptImg/>
                                                Summarize the key findings of the research paper on the impact of climate change on marine life
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* job hunting */}
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <TitleImg icon={JobIcon}/> Hunting for a job?
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                Job seekers often go through numerous job listings. This tool can help them
                                extract relevant details like job description, qualifications, and company information
                                quickly just by providing a URL or uploading a file.
                                <div className="mt-4">
                                            <span className="code">
                                                <PromptImg/>
                                                Extract job descriptions and qualifications
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* summarizing online content */}
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <TitleImg icon={WebPageIcon}/> Need to summarize online articles or blogs?
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                Facing an overload of information from lengthy online
                                articles or blogs? This tool is designed to alleviate this by summarizing content from a
                                provided URL, giving you the essence of the article or blog in a concise format, saving you
                                reading time.
                                <div className="mt-4">
                                            <span className="code">
                                                <PromptImg/>
                                                Summarize the key points and main ideas of this article in bullet points
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* data analysis */}
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <TitleImg icon={ChartIcon}/> Need to analyze data and generate charts?
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                Upload an Excel or a CSV to generate charts to visualize
                                trends, comparisons, and more. Whether you are looking to understand sales patterns,
                                customer behavior, or any other data-driven aspect, this tool brings the insights to your
                                fingertips.
                                <div className="mt-4">
                                    Considering the following excerpt from an Excel:
                                    <div className="mt-4">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Category</th>
                                                    <th scope="col">Product Name</th>
                                                    <th scope="col">Sales</th>
                                                    <th scope="col">Salesperson</th>
                                                    <th scope="col">Transaction Date</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>Electronics</td>
                                                    <td>Laptop Model A</td>
                                                    <td>1000</td>
                                                    <td>Sakura</td>
                                                    <td>15/03/2023 11:30:17</td>
                                                </tr>
                                                <tr>
                                                    <td>Furniture</td>
                                                    <td>Office Chair</td>
                                                    <td>150</td>
                                                    <td>Amara</td>
                                                    <td>17/03/2023 17:22:40</td>
                                                </tr>
                                                <tr>
                                                    <td>Stationery</td>
                                                    <td>Desk Organizer</td>
                                                    <td>20</td>
                                                    <td>Aisha</td>
                                                    <td>01/04/2023 14:58:20</td>
                                                </tr>
                                                <tr>
                                                    <td>Electronics</td>
                                                    <td>Smartphone Model X</td>
                                                    <td>800</td>
                                                    <td>Amara</td>
                                                    <td>15/04/2023 20:13:07</td>
                                                </tr>
                                                <tr>
                                                    <td>Stationery</td>
                                                    <td>Notebook</td>
                                                    <td>10</td>
                                                    <td>Rohan</td>
                                                    <td>03/05/2023 19:11:14</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        Find out the total sales per product, considering a minimum sales value of 100:
                                        <div className="mt-2">
                                            <span className="code">
                                                <PromptImg/>
                                                Generate a pie chart from the columns "Product Name" and the sum of Sales where Sales is greater than 100
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        Sales per category:
                                        <div className="mt-2">
                                            <span className="code">
                                                <PromptImg/>
                                                Create a donut chart from the columns Category and the average of Sales using the colors red, green, and blue, offset the slice Electronics by 20%
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        Sales count per Salesperson:
                                        <div className="mt-2">
                                            <span className="code">
                                                <PromptImg/>
                                                Create a donut chart from the columns Salesperson based on the count of Sales, where Category is not "Stationery", display values as percentages, don't show a legend
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        Total sales per month:
                                        <div className="mt-2">
                                            <span className="code">
                                                <PromptImg/>
                                                Generate a bar chart from the columns "Transaction Date" using a resampling interval of one month and the sum of column Sales, where Sales is between 100 and 10000, format datetime as Month & year, example: Jan 2023
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* the sky is the limit */}
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <TitleImg icon={AiIcon}/> The sky is the limit!
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                We're constantly improving FileDigest and we would love to hear your thoughts on it. Get in touch <Link to={'/contact-us'}>here</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4 className="mt-5">Featured Insights</h4>
            {/* Blog 1 */}
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <TitleImg icon={BlogIcon}/> Unlocking the Power of Summarized Content with FileDigest.ai
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <p>In today's hyper-connected, information-driven world, we find ourselves constantly inundated with an ever-growing mountain of content. From extensive research papers to exhaustive business reports, the sheer magnitude of information can easily lead to cognitive overload.</p>
                                <p>The challenge isn't just having access to information but being able to distill its essence in a time-effective manner. Enter the realm of summarized content, where FileDigest.ai is making significant waves. <a href={'/blog/Unlocking-the-Power-of-Summarized-Content-with-FileDigest-ai'}>Read more</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Blog 2 */}
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <TitleImg icon={BlogIcon}/> Introducing YouTube Video Summarization and Q&A on FileDigest.ai
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <p>We are excited to announce a powerful new feature on FileDigest.ai that will revolutionize the way you interact with educational content. Now, you can summarize YouTube videos and engage in interactive Q&A sessions about the video's content. <a href={'/blog/YouTube-Video-Summarization-and-QnA'}>Read more</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Help;