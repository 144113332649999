import React, {Suspense} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './main';
import Header from "./header";
import Footer from "./footer";
import PrivacyPolicy from "./privacy-policy";
import TermsAndConditions from "./terms-and-conditions";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ContactUs from "./contact-us";
import BlogPost1 from "./blog/Unlocking-the-Power-of-Summarized-Content-with-FileDigest-ai";
import BlogPost2 from "./blog/YouTube-Video-Summarization-and-QnA";

function App() {
    return (
        <div className="d-flex flex-column min-vh-100">
            <Suspense fallback={<span/>}>
                <BrowserRouter>
                    <Header/>
                    <Routes>
                        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                        <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
                        <Route path="/contact-us" element={<ContactUs/>}/>
                        <Route path="/blog/Unlocking-the-Power-of-Summarized-Content-with-FileDigest-ai" element={<BlogPost1/>}/>
                        <Route path="/blog/YouTube-Video-Summarization-and-QnA" element={<BlogPost2/>}/>
                        <Route path="/" element={<Main/>}/>
                    </Routes>
                    <Footer/>
                </BrowserRouter>
            </Suspense>
        </div>
    );
}

export default App;
