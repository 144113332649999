import React from 'react';
import './styles.css';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
    return (
        <>
            <Helmet>
                <title>Terms and Conditions</title>
            </Helmet>
            <main className="container flex-grow-1 mt-5 mb-5">
                <h2>Terms and Conditions</h2>
                <br/>

                <h3>Acceptance of Terms</h3>
                <p>By using the FileDigest website and services, you agree to these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use our services.</p>

                <h3>Use of Services</h3>
                <p>FileDigest provides a platform for users to upload files, summarize them using AI, and interact with the documents through a Q&A format. We also allow users to enter URLs to web content or documents for summarization and interaction.</p>

                <h3>Access to the Site</h3>
                <p>Access to our site is permitted on a temporary basis, and we reserve the right to withdraw or amend the services we provide on our site without notice. We will not be liable if for any reason our site is unavailable at any time or for any period.</p>

                <h3>Intellectual Property Rights</h3>
                <p>Unless otherwise stated, FileDigest owns the intellectual property rights for all material on FileDigest. All intellectual property rights are reserved.</p>

                <h3>User Content</h3>
                <p>You are solely responsible for the content you upload or provide to our platform. By uploading content, you warrant and represent that you have the right to do so and that the content does not infringe any third-party rights.</p>

                <h3>Limitations and exclusions of liability</h3>
                <p>To the extent that our website and the information and services on our website are provided free of charge, we will not be liable for any loss or damage of any nature. FileDigest will not be liable for any indirect, incidental, special, punitive, or consequential damages of any kind arising out of or relating to your use of our services.</p>

                <h3>Breaches of these terms and conditions</h3>
                <p>Without prejudice to FileDigest’s other rights under these terms and conditions, if you breach these terms and conditions in any way, FileDigest may take such action as FileDigest deems appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.</p>

                <h3>Variation</h3>
                <p>We may revise these terms and conditions from time-to-time. Revised terms and conditions will apply to the use of our website from the date of the publication of the revised terms and conditions on our website. Please check this page regularly to ensure you are familiar with the current version.</p>

                <h3>Law and jurisdiction</h3>
                <p>These terms and conditions will be governed by and construed in accordance with the laws of the user's location, and any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of the user's location. Please note that legal rights can vary by country or state, so some of the above limitations may not apply to you if you reside outside of our main operational jurisdiction.</p>

                <h3>Contacting Us</h3>
                <p>If there are any questions regarding these terms and conditions, you may contact us using the information on our Contact Us page.</p>

            </main>
        </>
    );
};

export default TermsAndConditions;