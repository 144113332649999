import React, {useRef} from 'react';
import {PRIMARY_COLOR} from "./config";

const FileSelector = ({setSelectedFile, selectedFile}) => {
    const fileRef = useRef(null);
    const style={};
    if(selectedFile === null)
    {
        style.backgroundColor = PRIMARY_COLOR;
        style.borderColor = PRIMARY_COLOR;
    }

    return (
        <>
            <input type="file" style={{display: 'none'}} ref={fileRef} onChange={(event) => setSelectedFile(event.target.files[0])}/>
            <button className={`btn ${selectedFile === null? 'btn-primary': 'btn-outline-secondary'}`} style={style} onClick={() => fileRef.current.click()}>Select file</button>
        </>
    );
};


export default FileSelector;