import React from 'react';
import PartyPopperIcon from './icons/party-popper.svg';


const Intro = () => {
    return (
        <div className="text-muted">
            <div className="mt-5">
                <span className="badge rounded-pill bg-secondary me-2">1</span>
                <b>Type your prompt below: </b>
                <span>
                    Use the text input below to enter your prompt. Clearly specify the information you are seeking, use precise language and avoid ambiguity.
                </span>
            </div>
            <div className="mt-3">
                <span className="badge rounded-pill bg-secondary me-2">2</span>
                <b>Provide context: </b>
                <span>
                    Give background information or context if it is necessary for understanding the question.
                </span>
            </div>
            <div className="mt-3">
                <span className="badge rounded-pill bg-secondary me-2">3</span>
                <b>Specify the format: </b>
                <span>
                    If you are looking for an answer in a specific format (e.g., a list, a summary, step-by-step), mention this in your prompt.
                </span>
            </div>
            <div className="mt-3">
                <span className="badge rounded-pill bg-secondary me-2">4</span>
                <b>Ask follow-up questions: </b>
                <span>
                    If the initial response is not satisfactory, ask follow-up questions to clarify or gather more details.
                </span>
            </div>
            <div className="mt-5">
                <img src={PartyPopperIcon} alt={'New'} style={{width: '25px'}}/>
                <span className="ms-2">
                    You can now summarize YouTube videos, <a href={'/blog/YouTube-Video-Summarization-and-QnA'}>learn more</a>.
                </span>
            </div>
        </div>
    );
};

export default Intro;