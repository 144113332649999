import React, {useState} from 'react';
import '../styles.css';
import {Helmet} from 'react-helmet';
import {PRIMARY_COLOR} from "../config";
import CONTACT_US_GQL from './contact-us.graphql';
import {getReCaptchaV3Token, retryHttpCall, submitMutation} from "../utils";
import {useApolloClient} from "@apollo/client";

const ContactUs = () => {
    const apolloClient = useApolloClient();
    const [formValues, setFormValues] = useState({name: '', email: '', message: ''});
    const [formValidity, setFormValidity] = useState({name: true, email: true, message: true});
    const [isSubmitting, setIsSubmitting] = useState(false);

    /**
     * On change
     */
    const onFieldChange = ({target}) => {
        setFormValues({...formValues, [target.id]: target.value});
    };

    const isValidEmail = (email) => {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
    };

    /**
     * Validation
     */
    const validateForm = () => {
        const formStatus = {...formValidity};
        formStatus.name = formValues.name.trim().length > 1;
        formStatus.email = isValidEmail(formValues.email);
        formStatus.message = formValues.message.trim().length > 5;
        setFormValidity(formStatus);
        return formStatus.name && formStatus.email && formStatus.message;
    };

    /**
     * On submission
     */
    const onSubmitClick = async () => {
        if(!validateForm())
        {
            return;
        }
        setIsSubmitting(true);
        const payload = {
            name: formValues.name.trim(),
            email: formValues.email.trim(),
            message: formValues.message.trim(),
            reCaptchaToken: await getReCaptchaV3Token()
        };
        const {error} = await retryHttpCall(submitMutation, [apolloClient, CONTACT_US_GQL, payload]);
        if(!!error)
        {
            alert('Something is not right!');
            setIsSubmitting(false);
            return;
        }
        alert(`Thank you for reaching out! We’ve received your message and will get back to you as soon as we can.`);
        window.location.href = '/';
    };

    return (
        <>
            <Helmet>
                <title>Contact Us</title>
            </Helmet>
            <main className="container flex-grow-1 mt-5 mb-5">
                <h2>Contact Us</h2>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input type="text" value={formValues.name} onChange={onFieldChange} className={'form-control' + (!formValidity.name? ' is-invalid': '')}
                           id="name" name="name" required/>
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email address</label>
                    <input type="email" value={formValues.email} onChange={onFieldChange} className={'form-control' + (!formValidity.email? ' is-invalid': '')}
                           id="email" name="email" aria-describedby="emailHelp" required/>
                    <div id="emailHelp" className="form-text">We'll never share your email with anyone else.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="message" className="form-label">Message</label>
                    <textarea value={formValues.message} onChange={onFieldChange} className={'form-control' + (!formValidity.message? ' is-invalid': '')}
                              id="message" name="message" rows="3" required/>
                </div>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting} onClick={onSubmitClick}
                        style={{backgroundColor: PRIMARY_COLOR, borderColor: PRIMARY_COLOR}}>Submit</button>
            </main>
        </>
    );
};

export default ContactUs;